import router from '@/router'
import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchMeals() {
      return new Promise((resolve, reject) => {
        axios
          .get('/categories?options={"parent_id":"1"}')
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchMeal(ctx, _id) {
      const { id } = _id

      return new Promise((resolve, reject) => {
        axios
          .get(`/categories/view/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addMeal(ctx, mealData) {
      const bodyFormData = new FormData()
      bodyFormData.append('name_en', mealData.name_en)
      bodyFormData.append('name_ar', mealData.name_ar)
      bodyFormData.append('image', mealData.image)
      bodyFormData.append('add_ones', mealData.add_ones === true ? 1 : 0)
      bodyFormData.append('used_in_all_meals', mealData.used_in_all_meals === true ? 1 : 0)
      bodyFormData.append('parent_id', 1)

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/categories/create   ',
          data: bodyFormData,
          headers: {
            contentType: 'multipart/form-data',
          },
        })
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
    editMeal(ctx, updatedMealData) {
      const bodyFormData = new FormData()
      bodyFormData.append('name_en', updatedMealData.name_en)
      bodyFormData.append('name_ar', updatedMealData.name_ar)
      updatedMealData.image && bodyFormData.append('image', updatedMealData.image)
      bodyFormData.append('add_ones', updatedMealData.add_ones === true || updatedMealData.add_ones === 1 ? 1 : 0)
      bodyFormData.append(
        'used_in_all_meals',
        updatedMealData.used_in_all_meals === true || updatedMealData.used_in_all_meals === 1 ? 1 : 0,
      )
      bodyFormData.append('parent_id', 1)
      bodyFormData.append('id', router.currentRoute.params.id)

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/categories/update   ',
          data: bodyFormData,
          headers: {
            contentType: 'multipart/form-data',
          },
        })
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteMeal(ctx, id) {
      const data = { id }

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/categories/delete',
          data,
        })
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
