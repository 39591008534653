import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function useMealsList() {
  const mealsListTable = ref([])

  const tableColumns = [
    { text: 'Name (En)', value: 'name_en' },
    { text: 'Name (Ar)', value: 'name_ar' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const totalMealsListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })
  const sizesTotalLocal = ref([])
  const selectedRows = ref([])

  // fetch data
  const fetchMeals = () => {
    store
      .dispatch('app-meals/fetchMeals')
      .then(response => {
        const { filteredData, total, sizesTotal } = response.data.data
        mealsListTable.value = filteredData
        totalMealsListTable.value = total
        sizesTotalLocal.value = sizesTotal

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([searchQuery, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchMeals()
  })

  return {
    mealsListTable,
    tableColumns,
    searchQuery,
    totalMealsListTable,
    loading,
    options,
    sizesTotalLocal,
    selectedRows,
    fetchMeals,
  }
}
